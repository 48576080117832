<template>
  <div>
    <br /><br /><br /><br /><br />
    <v-container fluid>
      <v-row>
        <v-col xs12 sm12 md12 lg12 style="text-align: center">
          <h3>Account</h3>
          <p>{{ username }}</p>
          <br />
          <v-card
            class="mx-auto"
            max-width="300"
            @click="UpdateProfile"
            style="text-align: left"
          >
            <v-icon size="50px" style="padding-left: 10px"
              >mdi-card-account-details-outline</v-icon
            >

            <v-card-title class="pb-0"> Personal info </v-card-title>

            <v-card-text class="text--primary">
              <div>Provide personal details and how we can reach you</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  computed: {
    username() {
      return this.$store.state.user?.email;
    },
  },
  methods: {
    UpdateProfile() {
      this.$router.push({ name: 'UpdateProfile' });
    },
  },
};
</script>
